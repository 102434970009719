































import {
  defineComponent,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api";
import { debounce } from "lodash-es";
import InfoPopup from "@/shared/components/InfoPopup";
import { checkPasswordComplexity } from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusUserPassword",
  components: { InfoPopup },
  props: {
    title: String,
    value: {
      type: [String, Number],
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: "text",
    },
    loading: {
      type: Boolean,
      defaullt: false,
    },
    customErrors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    infos: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    inputStyles: {
      type: String,
    },
    showSafePasswordNotice: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const formData = reactive({
      password: props.value,
      showPassword: false,
    });

    watch(
      () => formData.password,
      debounce((val) => {
        if (val.length >= 8 && !checkPasswordComplexity(val)) {
          ctx.emit("set-password-complexity-too-low", true);
        } else {
          ctx.emit("set-password-complexity-too-low", false);
        }
      })
    );

    return {
      formData,
    };
  },
});
