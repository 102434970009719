

























import { defineComponent, ref } from "@vue/composition-api";
import { nanoid } from "nanoid";

export default defineComponent({
  name: "InfoPopup",
  props: {
    color: {
      type: String,
      default: "text-orange-500",
    },
    iconText: {
      type: String,
      default: undefined,
    },
  },
  setup(_, { root }) {
    const name = ref(nanoid());

    function showModal() {
      root.$modal.show(name.value);
    }

    return {
      name,
      showModal,
    };
  },
});
