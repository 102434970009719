




































































































































































import { defineComponent, PropType } from "@vue/composition-api";

import { RasmusUserKidParams, RasmusUserParams } from ".";
import LoadingButton from "@/shared/components/LoadingButton";
import { genderRadioOptions } from "@/features/rasmus-kids-club/shared/constants";
import { apiDateFormat } from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusUserDetail",
  components: { LoadingButton },
  props: {
    userData: {
      type: Object as PropType<RasmusUserParams>,
      default: () => ({} as RasmusUserParams),
    },
    editLink: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const getGenderTextForKey = (genderKey: string) => {
      return (
        genderRadioOptions.find((option) => option.value === genderKey)
          ?.title ?? undefined
      );
    };

    const getKidFullName = (kid: RasmusUserKidParams) =>
      `${kid.firstName} ${
        kid.lastName ? kid.lastName : props.userData.lastNameParentOne
      }`;

    return {
      apiDateFormat,
      getGenderTextForKey,
      getKidFullName,
    };
  },
});
