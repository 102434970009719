







































































































































































































































































import {
  defineComponent,
  reactive,
  PropType,
  computed,
  ref,
} from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";

import { RasmusUserParams, RasmusUserKidParams } from ".";
import LoadingButton from "@/shared/components/LoadingButton";
import RasmusUserKid from "./RasmusUserKid.vue";
import RasmusUserPassword from "./RasmusUserPassword.vue";

export default defineComponent({
  name: "RasmusUserForm",
  components: {
    RasmusUserKid,
    ValidationObserver,
    LoadingButton,
    RasmusUserPassword,
  },
  props: {
    userData: {
      type: Object as PropType<RasmusUserParams>,
      default: () => ({} as RasmusUserParams),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    deactivateMessage: {
      type: String,
      default: "",
    },
    privacyLink: {
      type: String,
      default: "",
    },
    newsletterLink: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const userData = props.userData;
    const isNewUser = !props.userData.uid;
    const newsletterCheckboxText = `Ich möchte den Newsletter mit den Rasmus Kids Club Highlights erhalten.`;
    // const privacyText = `Mit der Registrierung akzeptieren Sie die <a href="${props.privacyLink}" target="_blank">Nutzungsbedingungen</a>`;
    const termsText = `Informationen zum <a href="https://www.evo-energie.de/datenschutz" target="_blank"> Datenschutz finden Sie hier.</a>`;

    const generateRandomNumber = () => {
      return Math.floor(Math.random() * 10000);
    };

    const kidTemplate: RasmusUserKidParams = {
      action: "ADD",
      gender: "",
      firstName: "",
      lastName: "",
      birthday: "",
      uid: undefined,
      temporaryUniqueIdentifier: generateRandomNumber(),
    };

    const formData = reactive({
      uid: userData.uid ?? undefined,
      email: userData.email ?? "",
      emailConfirm: userData.email ?? "",
      customerNumber: userData.customerNumber ?? "",
      address: userData.address ?? "",
      streetNumber: userData.streetNumber ?? "",
      city: userData.city ?? "",
      zip: userData.zip ?? "",
      terms: userData.terms ?? "",
      password: "",
      passwordConfirm: "",
      newsletter: userData.newsletter ?? "",
      firstNameParentOne: userData.firstNameParentOne ?? "",
      lastNameParentOne: userData.lastNameParentOne ?? "",
      firstNameParentTwo: userData.firstNameParentTwo ?? "",
      lastNameParentTwo: userData.lastNameParentTwo ?? "",
      kids: userData.kids ?? [kidTemplate],
    });

    const findKidByIdentifier = (data: {
      temporaryIdentifier?: number;
      uid?: number;
    }) => {
      let kid = undefined;
      if (data.uid) {
        kid = formData.kids.find((kid) => kid.uid === data.uid);
      } else if (data.temporaryIdentifier) {
        kid = formData.kids.find(
          (kid) => kid.temporaryUniqueIdentifier === data.temporaryIdentifier
        );
      }

      return kid;
    };

    const invalidBirthdayKidIds: number[] = reactive([]);
    const passwordComplexityTooLow = ref(false);

    const setPasswordComplexityTooLow = (bool: boolean) => {
      passwordComplexityTooLow.value = bool;
    };

    const addInvalidKidId = (id: number) => {
      if (!invalidBirthdayKidIds.includes(id)) {
        invalidBirthdayKidIds.push(id);
      }
    };

    const removeInvalidKidId = (id: number | undefined) => {
      if (id && invalidBirthdayKidIds.includes(id)) {
        invalidBirthdayKidIds.splice(invalidBirthdayKidIds.indexOf(id), 1);
      }
    };

    const addKid = () => {
      const template = {
        ...kidTemplate,
        temporaryUniqueIdentifier: generateRandomNumber(),
      };

      formData.kids.push(template);
    };

    const updateKidData = (payload: {
      temporaryIdentifier?: number;
      uid?: number;
      data: RasmusUserKidParams;
    }) => {
      const element = findKidByIdentifier({
        temporaryIdentifier: payload.temporaryIdentifier,
        uid: payload.uid,
      });

      let index = undefined;

      if (element) {
        index = formData.kids.indexOf(element);
      } else {
        return;
      }

      if (index !== undefined && index !== -1) {
        formData.kids.splice(index, 1, payload.data);
      }
    };

    const removeKidFromArray = (payload: {
      temporaryIdentifier?: number;
      uid?: number;
    }) => {
      const element = findKidByIdentifier({
        temporaryIdentifier: payload.temporaryIdentifier,
        uid: payload.uid,
      });

      let index = undefined;

      if (element) {
        index = formData.kids.indexOf(element);
      } else {
        return;
      }

      removeInvalidKidId(getKidFormKey(element));
      formData.kids.splice(index, 1);
    };

    const getKidFormKey = (kid: RasmusUserKidParams) => {
      return kid.uid ? kid.uid : kid.temporaryUniqueIdentifier;
    };

    const handleSubmit = () => {
      ctx.emit("submit", formData);
    };

    const deactivateAccount = () => {
      const approve = confirm("Wollen Sie Ihren Account wirklich löschen?");

      if (approve === false) {
        return;
      }

      ctx.emit("deactivate-account");
    };

    const customPasswordErrors = computed(() => {
      if (passwordComplexityTooLow.value) {
        return [
          "Bitte nutzen Sie ein sicheres Passwort. Verwenden Sie für ein sicheres Passwort Zahlen, Buchstaben (klein/groß) und Sonderzeichen.",
        ];
      } else {
        return undefined;
      }
    });

    const customPasswordConfirmErrors = computed(() => {
      if (
        isNewUser &&
        formData.password?.length &&
        formData.passwordConfirm?.length &&
        formData.password !== formData.passwordConfirm
      ) {
        return ["Die Passwörter stimmen nicht überein"];
      } else {
        return undefined;
      }
    });

    const customEmailConfirmErrors = computed(() => {
      if (
        formData.email?.length &&
        formData.emailConfirm?.length &&
        formData.email !== formData.emailConfirm
      ) {
        return ["Die E-Mail-Adressen stimmen nicht überein"];
      } else {
        return undefined;
      }
    });

    const filteredKids = computed((): RasmusUserKidParams[] => {
      return formData.kids.filter((kid) => kid.action !== "DELETE");
    });

    return {
      addKid,
      removeKidFromArray,
      updateKidData,
      getKidFormKey,
      handleSubmit,
      deactivateAccount,
      newsletterCheckboxText,
      // privacyText,
      termsText,
      filteredKids,
      isNewUser,
      formData,
      customPasswordConfirmErrors,
      customEmailConfirmErrors,
      addInvalidKidId,
      removeInvalidKidId,
      setPasswordComplexityTooLow,
      customPasswordErrors,
      invalidKids: invalidBirthdayKidIds,
    };
  },
});
