





































































import {
  defineComponent,
  reactive,
  ref,
  watch,
  PropType,
  computed,
} from "@vue/composition-api";
import { debounce } from "lodash-es";
import { addDays, subYears } from "date-fns";

import { RasmusUserKidParams } from ".";

export default defineComponent({
  name: "RasmusUserKid",
  components: {},
  props: {
    kidData: {
      type: Object as PropType<RasmusUserKidParams>,
      default: () => ({} as RasmusUserKidParams),
    },
    index: {
      type: Number,
      default: 0,
    },
    identifier: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, ctx) {
    const kidData = props.kidData;
    const isNewKid = !props.kidData.uid;
    const minDate = subYears(new Date(), 11);
    const maxDate = addDays(new Date(), 1);

    const kidHeadlineName = computed(() => {
      return formData.firstName
        ? formData.firstName
        : `Kind ${props.index + 1}`;
    });
    const genderRadioOptions = ref([
      {
        title: "Junge",
        value: "M",
      },
      {
        title: "Mädchen",
        value: "W",
      },
    ]);

    const formData = reactive({
      ...kidData,
      gender: kidData.gender
        ? kidData.gender
        : genderRadioOptions.value[0]?.value,
    });

    const emitRangeError = (value: string[]) => {
      if (value && value.length) {
        ctx.emit("add-invalid-kid-id", props.identifier);
      } else {
        ctx.emit("remove-invalid-kid-id", props.identifier);
      }
    };

    const emitKidUpdate = () => {
      ctx.emit("updateKidData", {
        temporaryIdentifier: formData.temporaryUniqueIdentifier,
        uid: formData.uid,
        data: formData,
      });
    };

    watch(
      formData,
      debounce(() => {
        if (!isNewKid) {
          formData.action = "UPDATE";
        }

        emitKidUpdate();
      }, 500)
    );

    const updateGender = (gender: { label: string; value: string }) => {
      formData.gender = gender.value;
    };

    const removeKid = () => {
      const approve = confirm(
        `Wollen Sie ${kidHeadlineName.value} Wirklich entfernen?`
      );

      if (approve === false) {
        return;
      }

      if (isNewKid) {
        ctx.emit("removeKidFromArray", {
          temporaryIdentifier: formData.temporaryUniqueIdentifier,
        });
      } else {
        formData.action = "DELETE";
        emitKidUpdate();
      }
    };

    // const getSelectedGender = () => {
    //   return (
    //     genderRadioOptions.value.find(
    //       (option) => option.value === formData.gender
    //     )?.value ?? genderRadioOptions.value[0]?.value
    //   );
    // };

    return {
      formData,
      genderRadioOptions,
      updateGender,
      removeKid,
      // getSelectedGender,
      kidHeadlineName,
      minDate,
      maxDate,
      emitRangeError,
    };
  },
});
